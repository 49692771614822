import React from "react";

function DashboardAdmin() {
  return (
    <div className="container-fluid">
      <div
        className="card shadow border-0 mb-7 align-items-center justify-content-center"
        style={{ minHeight: "80vh" }}
      >
        <h2>Coming Soon ....</h2>
      </div>
    </div>
  );
}

export default DashboardAdmin;
